@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700;800&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: 'Archivo', sans-serif;
}

h3 {
    color:  #8B98A3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.selected-language {
    color: #5E5E5E;
    text-align: center;
    font-family: 'Archivo', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.selected-language-button {
    background: none;
    border: none;
    color: #5E5E5E;
    cursor: pointer;
    text-align: center;
    font-family: 'Archivo', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.terms-link {
    text-decoration: none;
    padding: 4px;
    color: #5E5E5E;
    font-family: 'Archivo', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}